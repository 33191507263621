import React from "react";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import { Shield, CreditCard, Clock, Users } from "lucide-react";

const PricingCard = ({ title, icon: Icon, children }) => (
  <div className="bg-white rounded-xl shadow-md p-8 hover:shadow-xl transition-all duration-300 border border-gray-100">
    <div className="flex items-center gap-3 mb-4">
      <div className="p-2 bg-blue-50 rounded-lg">
        <Icon className="w-6 h-6 text-blue-600" />
      </div>
      <h3 className="text-xl font-bold text-gray-800">{title}</h3>
    </div>
    <div className="text-gray-600 leading-relaxed space-y-4">{children}</div>
  </div>
);

const PricingAndRefunds = () => {
  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-blue-50 to-white">
      <NavBar />
      <main className="flex-grow">
        <div className="max-w-5xl mx-auto p-6 space-y-16">
          {/* Hero Section */}
          <section className="text-center py-16">
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
              Transparent Pricing &amp; Refunds
            </h1>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto leading-relaxed">
              At Genvorix, we believe in building lasting partnerships through transparent 
              pricing and client-friendly policies. Our flexible approach ensures you 
              receive maximum value while maintaining peace of mind.
            </p>
          </section>

          {/* Main Content Cards */}
          <div className="grid md:grid-cols-2 gap-8">
            <PricingCard title="Custom Solutions" icon={Users}>
              <div className="space-y-4">
                <p>
                  Every business has unique needs, which is why we take a personalized 
                  approach to pricing. Through detailed consultations, we develop 
                  solutions that perfectly match your requirements and budget.
                </p>
                <p>
                  Our team works closely with you through calls, emails, or meetings 
                  to understand your goals and create a tailored package that 
                  delivers exceptional value.
                </p>
              </div>
            </PricingCard>

            <PricingCard title="Transparent Pricing" icon={CreditCard}>
              <div className="space-y-4">
                <p>
                  Experience complete transparency in our pricing process. After understanding 
                  your requirements, we provide detailed quotes that clearly outline 
                  all costs and deliverables.
                </p>
                <p>
                  Our flexible pricing model ensures you only pay for what you need, 
                  maximizing the return on your investment in our services.
                </p>
              </div>
            </PricingCard>

            <PricingCard title="Refund Guarantee" icon={Shield}>
              <div className="space-y-4">
                <p>
                  Your satisfaction is guaranteed with our comprehensive refund policy:
                </p>
                <ul className="list-disc pl-5 space-y-2">
                  <li>
                    <span className="font-semibold">100% refund available</span> within 
                    the first two hours of service commencement
                  </li>
                  <li>
                    <span className="font-semibold">No-questions-asked refund policy</span> for 
                    up to two weeks after purchase
                  </li>
                </ul>
                <p>
                  We process all refund requests promptly, ensuring a hassle-free experience.
                </p>
              </div>
            </PricingCard>

            <PricingCard title="Our Commitment" icon={Clock}>
              <div className="space-y-4">
                <p>
                  We're dedicated to building long-term relationships based on trust 
                  and mutual success. Our commitment extends beyond project delivery 
                  to ensuring your complete satisfaction with our services.
                </p>
                <p>
                  Our support team is always available to address your questions and 
                  concerns, ensuring a seamless experience throughout our partnership.
                </p>
              </div>
            </PricingCard>
          </div>

          {/* CTA Section */}
          <section className="text-center py-12">
            <div className="bg-blue-50 rounded-2xl p-8 max-w-3xl mx-auto">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">
                Ready to Get Started?
              </h2>
              <p className="text-gray-600 mb-6">
                Contact us today to discuss your requirements and discover how we can 
                help you achieve your goals.
              </p>
              <button className="bg-blue-600 text-white px-8 py-3 rounded-lg hover:bg-blue-700 transition-colors duration-300 font-semibold">
                Contact Us
              </button>
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default PricingAndRefunds;