import React from "react";
import NavBar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import {
  Brain,
  Cpu,
  Network,
  GitBranch,
  BarChart,
  Laptop,
  ArrowRight,
} from "lucide-react";

const CourseSection = ({ title, icon: Icon, description, details, image }) => (
  <div className="group mb-8 border-2 border-gray-100 rounded-xl hover:border-blue-100 transition-all duration-300 bg-white hover:shadow-xl">
    <div className="p-8">
      <div className="flex flex-col lg:flex-row items-start gap-6">
        <div className="p-4 bg-blue-50 rounded-xl group-hover:bg-blue-100 transition-colors duration-300">
          <Icon className="w-8 h-8 text-blue-600" />
        </div>
        <div className="flex-1 space-y-6">
          <div>
            <h3 className="text-2xl font-bold text-gray-900 mb-3 group-hover:text-blue-700 transition-colors duration-300">
              {title}
            </h3>
            <p className="text-lg text-gray-600">{description}</p>
          </div>

          <div className="grid lg:grid-cols-2 gap-8">
            <div className="space-y-4">
              {details.map((detail, index) => (
                <div key={index} className="flex items-start gap-3 group/item">
                  <ArrowRight className="w-5 h-5 mt-1 text-blue-500 opacity-0 group-hover/item:opacity-100 transition-opacity duration-300" />
                  <p className="text-gray-700 group-hover/item:text-gray-900 transition-colors duration-300">
                    {detail}
                  </p>
                </div>
              ))}
            </div>
            <div className="relative h-64 bg-gradient-to-br from-blue-50 to-gray-50 rounded-xl overflow-hidden shadow-inner">
              <img
                src={`/images/${image}`}
                alt={`Illustration for ${title}`}
                className="object-cover w-full h-full opacity-90 group-hover:opacity-100 transition-opacity duration-300"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const AICourse = () => {
  const sections = [
    {
      title: "Foundations of Artificial Intelligence",
      icon: Brain,
      image: "AI.jpg",
      description:
        "Explore the fundamental concepts of AI, including its history, types, and core principles that drive modern AI systems.",
      details: [
        "Evolution of Artificial Intelligence and key milestones",
        "Different types of AI: Narrow AI vs General AI",
        "Core concepts of machine learning and deep learning",
        "Understanding AI ethics and responsible AI development",
        "Real-world applications and impact of AI technology",
      ],
    },
    {
      title: "Machine Learning Essentials",
      icon: Cpu,
      image: "AI.jpg",
      description:
        "Master the core concepts of machine learning, including supervised and unsupervised learning techniques.",
      details: [
        "Supervised learning algorithms and their applications",
        "Unsupervised learning methods for pattern recognition",
        "Feature engineering and data preprocessing techniques",
        "Model evaluation and performance metrics",
        "Practical implementation using popular ML libraries",
      ],
    },
    {
      title: "Deep Learning and Neural Networks",
      icon: Network,
      image: "AI.jpg",
      description:
        "Dive deep into neural networks architecture, training methods, and implementation using modern frameworks.",
      details: [
        "Neural network architecture and components",
        "Backpropagation and gradient descent optimization",
        "Convolutional Neural Networks (CNN) for image processing",
        "Recurrent Neural Networks (RNN) for sequential data",
        "Transfer learning and pre-trained models",
      ],
    },
    {
      title: "Natural Language Processing",
      icon: GitBranch,
      image: "AI.jpg",
      description:
        "Learn to process and analyze human language data using cutting-edge NLP techniques and transformers.",
      details: [
        "Text preprocessing and tokenization techniques",
        "Word embeddings and language models",
        "Transformer architecture and attention mechanisms",
        "Named Entity Recognition and Sentiment Analysis",
        "Building chatbots and language generation systems",
      ],
    },
    {
      title: "Computer Vision",
      icon: BarChart,
      image: "AI.jpg",
      description:
        "Master computer vision techniques for image and video analysis using deep learning approaches.",
      details: [
        "Image processing and feature extraction",
        "Object detection and recognition systems",
        "Image segmentation techniques",
        "Face detection and recognition",
        "Real-time video processing and analysis",
      ],
    },
    {
      title: "AI Applications and Deployment",
      icon: Laptop,
      image: "AI.jpg",
      description:
        "Learn to deploy AI models in real-world applications and understand production environments.",
      details: [
        "Model deployment strategies and best practices",
        "API development for AI services",
        "Cloud platforms for AI deployment",
        "Model monitoring and maintenance",
        "Scaling AI solutions in production",
      ],
    },
  ];

  return (
    <>
      <NavBar />
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <header className="text-center mb-20">
            <h1 className="text-5xl font-bold text-gray-900 mb-6">
              Advanced Artificial Intelligence and Applications
            </h1>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto leading-relaxed">
              Master the cutting-edge technologies driving AI innovation through
              hands-on projects and real-world applications. This comprehensive
              course covers everything from fundamental concepts to advanced AI
              implementations across 14 interactive sessions designed for
              practical learning.
            </p>
          </header>

          <main className="space-y-12">
            {sections.map((section, index) => (
              <CourseSection key={index} {...section} />
            ))}
          </main>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AICourse;
