import React, { useState, useRef } from "react";
import {
  HelpCircle,
  Search,
  Book,
  Server,
  DollarSign,
  MessageCircle,
  ChevronDown,
  ChevronRight,
  Zap,
  Award,
  Laptop,
} from "lucide-react";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";

const FAQsPage = () => {
  const [activeSection, setActiveSection] = useState("general");
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedFaqs, setExpandedFaqs] = useState({});

  const sectionRefs = {
    general: useRef(null),
    courses: useRef(null),
    technical: useRef(null),
    pricing: useRef(null),
    support: useRef(null),
  };

  const sections = [
    { id: "general", title: "General Questions", icon: HelpCircle },
    { id: "courses", title: "Courses & Training", icon: Book },
    { id: "technical", title: "Technical Requirements", icon: Server },
    { id: "pricing", title: "Pricing & Payment", icon: DollarSign },
    { id: "support", title: "Support", icon: MessageCircle },
  ];

  const faqData = {
    general: [
      {
        question: "What is Genvorix?",
        answer:
          "Genvorix is a leading technology training and consultancy platform specializing in advanced programming, cloud computing, and artificial intelligence. We provide comprehensive training solutions for individuals and organizations looking to enhance their technical capabilities.",
      },
      {
        question: "Who are your courses designed for?",
        answer:
          "Our courses are designed for both beginners and experienced professionals. Whether you're starting your journey in technology or looking to upgrade your skills, we have programs tailored to various experience levels.",
      },
      {
        question: "Do you offer certifications?",
        answer:
          "Yes, we provide industry-recognized certifications upon successful completion of our courses. These certifications are valuable additions to your professional portfolio.",
      },
    ],
    courses: [
      {
        question: "How long are the courses?",
        answer:
          "Course durations vary from 4 weeks to 6 months, depending on the program complexity and depth. Each course is structured to provide comprehensive knowledge while accommodating working professionals' schedules.",
      },
      {
        question: "Are the courses self-paced or instructor-led?",
        answer:
          "We offer both self-paced and instructor-led courses. Our instructor-led courses include live sessions, while self-paced courses provide flexibility with lifetime access to materials.",
      },
      {
        question: "What learning materials are provided?",
        answer:
          "Students receive access to video lectures, interactive coding exercises, downloadable resources, practice projects, and a private community forum for discussion and support.",
      },
    ],
    technical: [
      {
        question: "What are the system requirements?",
        answer:
          "Most courses require a computer with at least 8GB RAM, modern browser, and stable internet connection. Specific software requirements are listed in each course description.",
      },
      {
        question: "Do I need prior programming experience?",
        answer:
          "It depends on the course. Beginner courses don't require prior experience, while advanced courses may have prerequisites which are clearly stated in the course description.",
      },
      {
        question: "Can I access courses on mobile devices?",
        answer:
          "Yes, our platform is mobile-responsive. While we recommend a computer for coding exercises, you can access learning materials and videos on mobile devices.",
      },
    ],
    pricing: [
      {
        question: "What payment methods do you accept?",
        answer:
          "We accept major credit cards, PayPal, and bank transfers. For corporate training, we also offer invoice-based payments.",
      },
      {
        question: "Do you offer refunds?",
        answer:
          "Yes, we offer a 30-day money-back guarantee if you're not satisfied with your course. Terms and conditions apply.",
      },
      {
        question: "Are there any hidden fees?",
        answer:
          "No, our pricing is transparent with no hidden fees. The course price includes all materials and lifetime access to course updates.",
      },
    ],
    support: [
      {
        question: "How can I get help if I'm stuck?",
        answer:
          "We offer multiple support channels: course discussion forums, email support, and live chat during business hours. Instructor-led courses also include direct instructor support.",
      },
      {
        question: "What is your response time for support queries?",
        answer:
          "We aim to respond to all queries within 24 hours. Premium support members receive priority response within 4 hours.",
      },
      {
        question: "Do you offer career guidance?",
        answer:
          "Yes, we provide career guidance through our Career Success program, including resume reviews, interview preparation, and job search strategies.",
      },
    ],
  };

  const handleSectionClick = (sectionId) => {
    setActiveSection(sectionId);
    sectionRefs[sectionId].current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const toggleFaq = (sectionId, index) => {
    setExpandedFaqs((prev) => ({
      ...prev,
      [`${sectionId}-${index}`]: !prev[`${sectionId}-${index}`],
    }));
  };

  const filteredFaqs = Object.entries(faqData).reduce(
    (acc, [section, questions]) => {
      const filtered = questions.filter(
        (q) =>
          q.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
          q.answer.toLowerCase().includes(searchTerm.toLowerCase())
      );
      if (filtered.length > 0) {
        acc[section] = filtered;
      }
      return acc;
    },
    {}
  );

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <NavBar />

      {/* Hero Section */}
      <div className="relative overflow-hidden bg-gradient-to-r from-blue-700 to-blue-900 py-24">
        <div className="absolute inset-0 bg-blue-800 opacity-20"></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="text-center">
            <div className="mb-8 inline-flex items-center justify-center w-20 h-20 rounded-full bg-white/10 backdrop-blur-sm">
              <HelpCircle className="w-10 h-10 text-white" />
            </div>
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Frequently Asked Questions
            </h1>
            <p className="text-xl text-blue-100 max-w-2xl mx-auto">
              Find answers to common questions about our services and platform
            </p>
          </div>
        </div>
      </div>

      {/* Search Bar */}
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 -mt-8">
        <div className="bg-white rounded-xl shadow-lg p-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              placeholder="Search FAQs..."
              className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* Feature Cards */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {[
            {
              icon: Zap,
              title: "Quick Answers",
              desc: "Find solutions instantly",
            },
            {
              icon: Award,
              title: "Expert Support",
              desc: "Professional assistance",
            },
            {
              icon: Laptop,
              title: "24/7 Access",
              desc: "Help when you need it",
            },
          ].map(({ icon: Icon, title, desc }) => (
            <div
              key={title}
              className="bg-white rounded-xl shadow-lg p-6 transform hover:scale-105 transition-all duration-300"
            >
              <div className="flex items-center space-x-4">
                <div className="p-3 bg-blue-100 rounded-lg">
                  <Icon className="w-6 h-6 text-blue-600" />
                </div>
                <div>
                  <h3 className="font-medium text-gray-900">{title}</h3>
                  <p className="text-sm text-gray-500">{desc}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          {/* Sidebar Navigation */}
          <div className="hidden lg:block lg:col-span-3">
            <nav className="sticky top-6">
              <ul className="space-y-2">
                {sections.map((section) => {
                  const IconComponent = section.icon;
                  return (
                    <li key={section.id}>
                      <button
                        onClick={() => handleSectionClick(section.id)}
                        className={`w-full flex items-center space-x-3 px-4 py-3 text-left rounded-lg transition-all duration-200 ${
                          activeSection === section.id
                            ? "bg-blue-50 text-blue-600 shadow-sm"
                            : "hover:bg-gray-50 text-gray-600"
                        }`}
                      >
                        <IconComponent className="w-5 h-5" />
                        <span className="text-sm font-medium">
                          {section.title}
                        </span>
                        <ChevronRight
                          className={`w-4 h-4 ml-auto transition-transform duration-200 ${
                            activeSection === section.id
                              ? "transform rotate-90"
                              : ""
                          }`}
                        />
                      </button>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>

          {/* FAQ Content Area */}
          <div className="lg:col-span-9">
            <div className="space-y-12">
              {Object.entries(filteredFaqs).map(([sectionId, questions]) => (
                <section
                  key={sectionId}
                  ref={sectionRefs[sectionId]}
                  id={sectionId}
                  className="bg-white rounded-xl shadow-sm p-8"
                >
                  <div className="flex items-center space-x-3 mb-6">
                    {React.createElement(
                      sections.find((s) => s.id === sectionId).icon,
                      {
                        className: "w-6 h-6 text-blue-600",
                      }
                    )}
                    <h2 className="text-2xl font-bold text-gray-900">
                      {sections.find((s) => s.id === sectionId).title}
                    </h2>
                  </div>
                  <div className="space-y-4">
                    {questions.map((faq, index) => (
                      <div
                        key={index}
                        className="border border-gray-200 rounded-lg overflow-hidden"
                      >
                        <button
                          onClick={() => toggleFaq(sectionId, index)}
                          className="w-full flex items-center justify-between p-4 text-left hover:bg-gray-50 transition-colors duration-200"
                        >
                          <span className="font-medium text-gray-900">
                            {faq.question}
                          </span>
                          <ChevronDown
                            className={`w-5 h-5 text-gray-500 transition-transform duration-200 ${
                              expandedFaqs[`${sectionId}-${index}`]
                                ? "transform rotate-180"
                                : ""
                            }`}
                          />
                        </button>
                        {expandedFaqs[`${sectionId}-${index}`] && (
                          <div className="p-4 bg-gray-50 border-t border-gray-200">
                            <p className="text-gray-600">{faq.answer}</p>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </section>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FAQsPage;
