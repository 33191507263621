import React from 'react';
import NavBar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import { BookOpen, Code, Database, GitBranch, Calculator, Table, ArrowRight } from 'lucide-react';

const CourseSection = ({ title, icon: Icon, description, details,image }) => (
  <div className="group mb-8 border-2 border-gray-100 rounded-xl hover:border-blue-100 transition-all duration-300 bg-white hover:shadow-xl">
    <div className="p-8">
      <div className="flex flex-col lg:flex-row items-start gap-6">
        <div className="p-4 bg-blue-50 rounded-xl group-hover:bg-blue-100 transition-colors duration-300">
          <Icon className="w-8 h-8 text-blue-600" />
        </div>
        <div className="flex-1 space-y-6">
          <div>
            <h3 className="text-2xl font-bold text-gray-900 mb-3 group-hover:text-blue-700 transition-colors duration-300">
              {title}
            </h3>
            <p className="text-lg text-gray-600">{description}</p>
          </div>
          
          <div className="grid lg:grid-cols-2 gap-8">
            <div className="space-y-4">
              {details.map((detail, index) => (
                <div key={index} className="flex items-start gap-3 group/item">
                  <ArrowRight className="w-5 h-5 mt-1 text-blue-500 opacity-0 group-hover/item:opacity-100 transition-opacity duration-300" />
                  <p className="text-gray-700 group-hover/item:text-gray-900 transition-colors duration-300">
                    {detail}
                  </p>
                </div>
              ))}
            </div>
            <div className="relative h-64 bg-gradient-to-br from-blue-50 to-gray-50 rounded-xl overflow-hidden shadow-inner">
              <img
                src={`/images/${image}`}
                alt={`Illustration for ${title}`}
                className="object-cover w-full h-full opacity-90 group-hover:opacity-100 transition-opacity duration-300"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const PythonCourse = () => {
  const sections = [
    {
      title: "Introduction to Python",
      image:"intopython.jpg",
      icon: BookOpen,
      description: "Begin your Python journey with fundamental concepts and essential development tools. Learn the basics through interactive examples and hands-on practice.",
      details: [
        "Setting up Python development environment with step-by-step guidance",
        "Understanding Python's interactive shell and script execution",
        "Mastering Jupyter Notebooks for interactive development",
        "Writing and running your first Python program",
        "Best practices for Python code organization"
      ]
    },
    {
      title: "Data Types and Variables",
      image:"intopython.jpg",
      icon: Code,
      description: "Master Python's core data types and learn how to manipulate different kinds of data efficiently through practical examples.",
      details: [
        "Working with numbers (integers and floating-point)",
        "String manipulation and formatting techniques",
        "List operations and common methods",
        "Understanding tuples and their use cases",
        "Dictionary operations for key-value data"
      ]
    },
    {
      title: "Control Flow Statements",
      image:"intopython.jpg",
      icon: GitBranch,
      description: "Learn to control program execution flow with conditional statements and loops. Build robust and efficient programs.",
      details: [
        "Implementing decision making with if-elif-else statements",
        "Creating efficient for loops for iteration",
        "Using while loops for condition-based iteration",
        "Understanding break and continue statements",
        "Exception handling with try-except blocks"
      ]
    },
    {
      title: "Functions and Modules",
      image:"intopython.jpg",
      icon: Database,
      description: "Organize code into reusable functions and learn to work with Python modules. Master code organization and reusability.",
      details: [
        "Creating and calling functions with parameters",
        "Understanding return values and function scope",
        "Working with built-in functions and methods",
        "Importing and using Python modules",
        "Creating custom modules for code organization"
      ]
    },
    {
      title: "NumPy for Numerical Computations",
      image:"intopython.jpg",
      icon: Calculator,
      description: "Harness the power of NumPy for efficient numerical operations and data analysis. Learn essential skills for scientific computing.",
      details: [
        "Creating and manipulating NumPy arrays",
        "Performing mathematical operations on arrays",
        "Advanced indexing and array slicing",
        "Broadcasting and vectorization concepts",
        "Linear algebra operations with NumPy"
      ]
    },
    {
      title: "Pandas for Data Manipulation",
      image:"intopython.jpg",
      icon: Table,
      description: "Master data manipulation and analysis with Pandas library. Transform and analyze real-world datasets effectively.",
      details: [
        "Working with Series and DataFrame objects",
        "Data import/export from various file formats",
        "Data cleaning and preprocessing techniques",
        "Advanced indexing and data selection",
        "Group operations and data aggregation"
      ]
    }
  ];

  return (
    <><NavBar />
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <header className="text-center mb-20">
          <h1 className="text-5xl font-bold text-gray-900 mb-6">
            Python for Data Analytics
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto leading-relaxed">
            Master data analysis with Python through hands-on exercises and real-world projects. 
            This comprehensive course covers everything from basics to advanced concepts across 
            14 interactive sessions designed for practical learning.
          </p>
        </header>
        
        <main className="space-y-12">
          {sections.map((section, index) => (
            <CourseSection key={index} {...section} />
          ))}
        </main>
      </div>
    </div>
    <Footer />
    </>
  );
};

export default PythonCourse;