import React from 'react';
import NavBar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import { 
  Database, 
  LineChart, 
  Layers, 
  Share2, 
  Timer,
  BookOpen,
  PieChart,
  Calendar,
  Table,
  BarChart2,
  Globe,
  Cloud,
  Smartphone,
  Brain,
  Network,
  TreePine,
  ChevronRight
} from 'lucide-react';

const ClassSection = ({ number, title, topics, icon: Icon }) => (
  <div className="relative group">
    <div className="flex flex-col md:flex-row gap-6 py-8 px-6 rounded-xl transition-all duration-300 hover:bg-blue-50">
      {/* Left side with number and icon */}
      <div className="flex md:flex-col items-center md:items-start gap-4 md:w-32">
        <div className="flex items-center justify-center w-16 h-16 bg-white rounded-2xl shadow-lg group-hover:bg-blue-600 transition-all duration-300">
          <Icon className="w-8 h-8 text-blue-600 group-hover:text-white transition-colors duration-300" />
        </div>
        <div className="flex items-center gap-2">
          <span className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent">
            {number.toString().padStart(2, '0')}
          </span>
          <span className="text-sm text-blue-600 font-medium">CLASS</span>
        </div>
      </div>

      {/* Right side content */}
      <div className="flex-1 space-y-4">
        <div className="flex items-center gap-3">
          <h3 className="text-2xl font-bold text-gray-800 group-hover:text-blue-600 transition-colors duration-300">
            {title}
          </h3>
        </div>
        
        <div className="grid md:grid-cols-2 gap-4">
          {topics.map((topic, index) => (
            <div 
              key={index}
              className="flex items-start gap-3 p-3 bg-white rounded-lg shadow-sm group-hover:shadow-md transition-all duration-300"
            >
              <ChevronRight className="w-5 h-5 text-blue-500 mt-0.5 flex-shrink-0" />
              <p className="text-gray-600 group-hover:text-gray-900 transition-colors duration-300">
                {topic}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);
const PowerBICourse = () => {
  const classes = [
    {
      number: 1,
      title: "Introduction to Power BI",
      icon: Database,
      topics: [
        "What is Power BI?",
        "What is Business Intelligence?",
        "Getting Started with Power BI Desktop",
        "Data Modeling Explained",
        "Getting Data For the First Time"
      ]
    },
    {
      number: 2,
      title: "Building Your First Report",
      icon: LineChart,
      topics: [
        "Basic Data Modeling in Power Query",
        "Power BI Desktop Orientation",
        "Building the First Report"
      ]
    },
    {
      number: 3,
      title: "Report Building Fundamentals",
      icon: Layers,
      topics: [
        "Measures",
        "Filters Pane",
        "Slicers",
        "Exploring First Power BI Report"
      ]
    },
    {
      number: 4,
      title: "Advanced Data Modeling",
      icon: Table,
      topics: [
        "Data Modeling: Data Types, Split Columns, and Merge Columns",
        "Data Modeling: Merge Queries, Column Profiling",
        "Defining Measures"
      ]
    },
    {
      number: 5,
      title: "Visualization Techniques",
      icon: PieChart,
      topics: [
        "Sync Slicers and Scatter Charts",
        "Grouping, Card Visuals, and Conditional Formatting",
        "Data Modeling: Pivot and Unpivot",
        "Granularity and Relationships"
      ]
    },
    {
      number: 6,
      title: "Advanced Visualizations",
      icon: BarChart2,
      topics: [
        "Third Party Visuals",
        "Visualizing Global Economic Indicators",
        "DAX Focus: Calculating Growth Rates",
        "Hierarchies & Drill Up/Drill Down"
      ]
    },
    {
      number: 7,
      title: "Time Intelligence",
      icon: Calendar,
      topics: [
        "Bookmarks",
        "Date Tables and Calendar Functions",
        "Calculated Columns",
        "Measures vs Calculated Columns",
        "Sort by Column"
      ]
    },
    {
      number: 8,
      title: "Financial Analysis",
      icon: Timer,
      topics: [
        "Basic Finance Calculations",
        "Time-Intelligence Functions Part-1",
        "Time-Intelligence Functions Part-2"
      ]
    },
    {
      number: 9,
      title: "Advanced Data Operations",
      icon: BookOpen,
      topics: [
        "Data Entry",
        "Measure Tables",
        "Calculated Tables: SUMMARIZE and CROSSJOIN",
        "Create New Tables from Existing Data in Power Query",
        "Many-to-Many Relationships"
      ]
    },
    {
      number: 10,
      title: "Report Enhancement",
      icon: Share2,
      topics: [
        "Budget Analysis",
        "Report Formatting",
        "Chart Analytics"
      ]
    },
    {
      number: 11,
      title: "Power BI Service",
      icon: Cloud,
      topics: [
        "Introducing the Power BI Service",
        "Power BI Licenses",
        "Workspaces",
        "Shared Datasets"
      ]
    },
    {
      number: 12,
      title: "Sharing and Security",
      icon: Globe,
      topics: [
        "Publish to Web",
        "Workspace Security and Sharing",
        "Subscriptions & Personal Bookmarks",
        "Connecting to Power BI Datasets from Excel"
      ]
    },
    {
      number: 13,
      title: "Mobile Experience",
      icon: Smartphone,
      topics: [
        "Dashboards in the Power BI Service",
        "Creating Power BI Dashboard",
        "Building Mobile Power BI Reports",
        "Power BI Mobile App"
      ]
    },
    {
      number: 14,
      title: "Dataflows",
      icon: Network,
      topics: [
        "Introducing Dataflows",
        "Dataflows, Part 2",
        "Connecting to Dataflows from Power BI Desktop",
        "Datasets versus Dataflows"
      ]
    },
    {
      number: 15,
      title: "AI Integration",
      icon: Brain,
      topics: [
        "Data Lineage",
        "AI Visuals",
        "Key Influencers",
        "Smart Narrative Visual"
      ]
    },
    {
      number: 16,
      title: "Advanced Features",
      icon: TreePine,
      topics: [
        "Decomposition Tree",
        "Using Text in Data Visualization",
        "Publish an App",
        "Using and Updating Power BI Apps",
        "Power BI Report Server"
      ]
    }
  ];

  return (
    <>
    <NavBar />
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-white to-blue-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <header className="text-center mb-16">
          <div className="inline-flex items-center justify-center w-20 h-20 bg-blue-600 rounded-2xl shadow-lg mb-8">
            <Database className="w-10 h-10 text-white" />
          </div>
          <h1 className="text-5xl font-bold text-gray-900 mb-6">
            Power BI Course Outline
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto leading-relaxed">
            Master the art of business intelligence with our comprehensive Power BI course. 
            Learn through hands-on projects and real-world applications across 16 detailed sessions.
          </p>
          <div className="mt-8 inline-block px-8 py-4 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-xl font-semibold shadow-lg">
            Important Note: All concepts will be taught using multiple projects and real-life use cases
          </div>
        </header>

        <div className="bg-white rounded-3xl shadow-xl p-8 divide-y divide-gray-100">
          {classes.map((classItem) => (
            <ClassSection key={classItem.number} {...classItem} />
          ))}
        </div>
      </div>
    </div>
    <Footer />
  </>
  );
};

export default PowerBICourse;