import React from "react";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import {
  CheckCircle2,
  BookOpen,
  Database,
  BarChart3,
  Brain,
} from "lucide-react";

const PricingCard = ({
  icon: Icon,
  name,
  price,
  description,
  features,
  route,
}) => {
  const navigate = useNavigate();

  const handleEnroll = () => {
    navigate(route);
  };

  return (
    <div className="bg-white rounded-xl shadow-xl overflow-hidden transform transition-all duration-300 hover:scale-105 hover:shadow-2xl h-full">
      <div className="p-8 flex flex-col h-full">
        <div className="flex-grow">
          <div className="flex items-center justify-center w-16 h-16 bg-blue-100 rounded-full mx-auto mb-6">
            <Icon className="w-8 h-8 text-blue-600" />
          </div>
          <h2 className="text-2xl font-bold text-center text-gray-800 mb-2">
            {name}
          </h2>
          <div className="text-center mb-6">
            <span className="text-4xl font-bold text-gray-900">{price}</span>
            <span className="text-gray-600">/course</span>
          </div>
          <p className="text-gray-600 text-center mb-8">{description}</p>
          <ul className="space-y-4 mb-8">
            {features.map((feature, index) => (
              <li key={index} className="flex items-center text-gray-600">
                <CheckCircle2 className="w-5 h-5 text-green-500 mr-3 flex-shrink-0" />
                <span>{feature}</span>
              </li>
            ))}
          </ul>
        </div>
        <button
          onClick={handleEnroll}
          className="w-full py-3 px-6 text-white bg-blue-600 rounded-lg font-semibold transition-all duration-200 hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 focus:outline-none mt-auto"
        >
          Enroll Now
        </button>
      </div>
    </div>
  );
};

const Pricing = () => {
  const packages = [
    {
      icon: BookOpen,
      name: "Python Course",
      price: "35000 PKR",
      description:
        "Perfect for beginners who want to master Python programming",
      features: [
        "Complete Python fundamentals",
        "50+ hands-on exercises",
        "Real-world projects",
        "Certificate of completion",
      ],
      route: "/pricing/python",
    },
    {
      icon: Database,
      name: "SQL Course",
      price: "35000 PKR",
      description: "Learn database management with SQL from scratch",
      features: [
        "Database design principles",
        "Advanced SQL queries",
        "Performance optimization",
      ],
      route: "/pricing/sqlpackage",
    },
    {
      icon: BarChart3,
      name: "Power BI Course",
      price: "40000 PKR",
      description: "Create powerful business intelligence solutions",
      features: [
        "Data visualization",
        "DAX formulas mastery",
        "Interactive dashboards",
        "Report automation",
        "Advanced analytics",
        "Custom visuals",
      ],
      route: "/pricing/powerbi",
    },
    {
      icon: Brain,
      name: "Bootcamp",
      price: "120000 PKR",
      description: "Dive deep into artificial intelligence concepts",
      features: [
        "Machine learning basics",
        "Neural networks",
        "AI model deployment",
        "Industry applications",
      ],
      route: "/pricing/aipackage",
    },
  ];

  return (
    <>
      <NavBar />
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="text-center mb-16">
            <h1 className="text-4xl md:text-5xl font-extrabold text-gray-900 mb-4">
              Choose Your Learning Path
            </h1>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Invest in your future with our industry-leading courses designed
              to take your skills to the next level
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {packages.map((pkg, index) => (
              <PricingCard key={index} {...pkg} />
            ))}
          </div>

          <div className="mt-16 text-center">
            <p className="text-gray-600">
              Need a custom learning plan?{" "}
              <a
                href="#contact"
                className="text-blue-600 font-semibold hover:text-blue-700"
              >
                Contact us
              </a>{" "}
              for enterprise solutions.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Pricing;
