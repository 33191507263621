import {
  BookOpen,
  Users,
  CheckCircle,
  ArrowRight,
  Award,
  BarChart,
  Target,
  Star,
} from "lucide-react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const Home = () => {
  const [activeTestimonial, setActiveTestimonial] = useState(0);

  const testimonials = [
    {
      text: "The training programs have transformed our team's productivity. The ROI we've seen has been incredible, and our team's motivation is at an all-time high.",
      author: "Sarah Johnson",
    },
    {
      text: "Expert consultancy that delivered real results. Our business has grown 150% since working with them. Their strategic insights were game-changing.",
      author: "Michael Chen",
    },
    {
      text: "The custom solutions provided exactly what we needed. Their team's expertise and dedication to our success was evident throughout the project.",
      author: "Emma Rodriguez",
    },
  ];

  return (
    <div className="min-h-screen flex flex-col">
      <NavBar />
      <main className="flex-grow">
        <div className="space-y-20">
          {/* Hero Section */}
          <div className="bg-custom-blue text-white py-24">
            <div className="container mx-auto px-4 flex flex-col md:flex-row items-center">
              <div className="max-w-3xl text-center md:text-left mb-10 md:mb-0">
                <h1 className="text-4xl md:text-5xl font-bold mb-6 leading-tight">
                  Transform Your Business with Expert Training & Consultancy
                </h1>
                <p className="text-lg md:text-xl mb-8 text-blue-100">
                  Unlock your organization's potential with our industry-leading
                  training programs and consulting services. Join over 500+
                  successful businesses that have partnered with us.
                </p>
                <div className="flex flex-col md:flex-row gap-4 justify-center md:justify-start">
                  {/* <button className="bg-white text-blue-600 px-8 py-4 rounded-lg font-semibold hover:bg-blue-50 transition duration-300 flex items-center gap-2">
                    Get Started <ArrowRight size={20} />
                  </button> */}
                  <NavLink
                    to="/contact"
                    className="bg-white text-blue-600 px-8 py-4 rounded-lg font-semibold hover:bg-blue-50 transition duration-300 flex items-center gap-2"
                  >
                    Get Started <ArrowRight size={20} />
                  </NavLink>
                </div>
              </div>
              <div className="w-full md:w-[400px] h-[400px] md:mt-0 mt-10">
                <img
                  src="/images/Ad1.jpg"
                  className="w-full h-full object-contain"
                  alt=""
                />
              </div>
            </div>
          </div>

          {/* Stats Section */}
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8 text-center">
              {[
                { number: "500+", label: "Clients Served" },
                { number: "100%", label: "Success Rate" },
                { number: "50+", label: "Expert Consultants" },
                { number: "10+ Years", label: "Experience" },
              ].map((stat, index) => (
                <div key={index} className="p-6 bg-gray-50 rounded-lg">
                  <div className="text-3xl font-bold text-blue-600 mb-2">
                    {stat.number}
                  </div>
                  <div className="text-gray-600">{stat.label}</div>
                </div>
              ))}
            </div>
          </div>

          {/* Services Section */}
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-3xl font-bold mb-4">Our Services</h2>
              <p className="text-gray-600 max-w-2xl mx-auto">
                We offer comprehensive solutions tailored to meet your business
                needs, helping you achieve sustainable growth and success.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                {
                  icon: BookOpen,
                  title: "Professional Training",
                  description:
                    "Comprehensive training programs designed to enhance skills and boost productivity.",
                  features: [
                    "Custom Curriculum",
                    "Expert Trainers",
                    "Hands-on Workshops",
                  ],
                },
                {
                  icon: Users,
                  title: "Business Consulting",
                  description:
                    "Strategic consulting services to help your business grow and succeed.",
                  features: [
                    "Strategy Development",
                    "Process Optimization",
                    "Growth Planning",
                  ],
                },
                {
                  icon: CheckCircle,
                  title: "Custom Solutions",
                  description:
                    "Tailored solutions designed to meet your specific business needs.",
                  features: [
                    "Personalized Approach",
                    "Innovative Solutions",
                    "Ongoing Support",
                  ],
                },
              ].map((service, index) => (
                <div
                  key={index}
                  className="p-8 border rounded-xl hover:shadow-xl transition duration-300 bg-white"
                >
                  <service.icon className="text-blue-600 mb-4" size={40} />
                  <h3 className="text-xl font-bold mb-3">{service.title}</h3>
                  <p className="text-gray-600 mb-6">{service.description}</p>
                  <ul className="space-y-2">
                    {service.features.map((feature, i) => (
                      <li
                        key={i}
                        className="flex items-center gap-2 text-gray-600"
                      >
                        <CheckCircle size={16} className="text-green-500" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>

          {/* Why Choose Us Section */}
          <div className="bg-gray-50 py-20">
            <div className="container mx-auto px-4">
              <div className="text-center mb-16">
                <h2 className="text-3xl font-bold mb-4">Why Choose Us</h2>
                <p className="text-gray-600 max-w-2xl mx-auto">
                  We combine industry expertise with innovative solutions to
                  deliver exceptional results for our clients.
                </p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                {[
                  {
                    icon: Award,
                    title: "Expert Team",
                    description: "Industry-leading professionals",
                  },
                  {
                    icon: BarChart,
                    title: "Proven Results",
                    description: "Data-driven solutions",
                  },
                  {
                    icon: Target,
                    title: "Custom Approach",
                    description: "Tailored to your needs",
                  },
                  {
                    icon: Star,
                    title: "Quality Service",
                    description: "Excellence guaranteed",
                  },
                ].map((feature, index) => (
                  <div key={index} className="text-center p-6">
                    <div className="inline-block p-4 bg-blue-100 rounded-full mb-4">
                      <feature.icon className="text-blue-600" size={24} />
                    </div>
                    <h3 className="font-bold mb-2">{feature.title}</h3>
                    <p className="text-gray-600">{feature.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Testimonials Section */}
          <div className="container mx-auto px-4 py-16">
            <h2 className="text-3xl font-bold mb-12 text-center">
              What Our Clients Say
            </h2>
            <div className="max-w-4xl mx-auto">
              <div className="bg-white p-8 rounded-xl shadow-lg">
                <p className="text-gray-600 mb-6 text-lg italic">
                  "{testimonials[activeTestimonial].text}"
                </p>
                <div className="flex justify-between items-center">
                  <div>
                    <p className="font-semibold text-lg">
                      {testimonials[activeTestimonial].author}
                    </p>
                  </div>
                  <div className="flex gap-2">
                    {testimonials.map((_, index) => (
                      <button
                        key={index}
                        onClick={() => setActiveTestimonial(index)}
                        className={`w-3 h-3 rounded-full transition-colors ${
                          activeTestimonial === index
                            ? "bg-blue-600"
                            : "bg-gray-300"
                        }`}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* CTA Section */}
          <div className="bg-blue-600 text-white py-16">
            <div className="container mx-auto px-4 text-center">
              <h2 className="text-3xl font-bold mb-6">
                Ready to Transform Your Business?
              </h2>
              <p className="text-xl mb-8 text-blue-100 max-w-2xl mx-auto">
                Join hundreds of successful businesses that have already
                partnered with us. Let's start your journey to success today.
              </p>
             
              <NavLink
                to="/contact"
                className="inline-flex items-center gap-2 bg-white text-blue-600 px-8 py-4 rounded-lg font-semibold hover:bg-blue-50 transition duration-300 no-underline w-fit"
              >
                Schedule a Free Consultation
                <ArrowRight size={20} />
              </NavLink>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Home;
