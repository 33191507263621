import React from 'react';
import NavBar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import { Database, TableProperties, GitBranch, Code, Calculator, Table, ArrowRight } from 'lucide-react';

const CourseSection = ({ title, icon: Icon, description, details, image }) => (
  <div className="group mb-8 border-2 border-gray-100 rounded-xl hover:border-blue-100 transition-all duration-300 bg-white hover:shadow-xl">
    <div className="p-8">
      <div className="flex flex-col lg:flex-row items-start gap-6">
        <div className="p-4 bg-blue-50 rounded-xl group-hover:bg-blue-100 transition-colors duration-300">
          <Icon className="w-8 h-8 text-blue-600" />
        </div>
        <div className="flex-1 space-y-6">
          <div>
            <h3 className="text-2xl font-bold text-gray-900 mb-3 group-hover:text-blue-700 transition-colors duration-300">
              {title}
            </h3>
            <p className="text-lg text-gray-600">{description}</p>
          </div>
          
          <div className="grid lg:grid-cols-2 gap-8">
            <div className="space-y-4">
              {details.map((detail, index) => (
                <div key={index} className="flex items-start gap-3 group/item">
                  <ArrowRight className="w-5 h-5 mt-1 text-blue-500 opacity-0 group-hover/item:opacity-100 transition-opacity duration-300" />
                  <p className="text-gray-700 group-hover/item:text-gray-900 transition-colors duration-300">
                    {detail}
                  </p>
                </div>
              ))}
            </div>
            <div className="relative h-64 bg-gradient-to-br from-blue-50 to-gray-50 rounded-xl overflow-hidden shadow-inner">
              <img
                src={`/images/${image}`}
                alt={`Illustration for ${title}`}
                className="object-cover w-full h-full opacity-90 group-hover:opacity-100 transition-opacity duration-300"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const SQLCourse = () => {
  const sections = [
    {
      title: "Introduction to SQL and Databases",
      icon: Database,
      image: "Sql.jpg",
      description: "Begin your SQL journey by understanding database fundamentals and basic SQL concepts. Learn through hands-on practice with real database systems.",
      details: [
        "Understanding database management systems (DBMS) and their types",
        "Setting up your first database and SQL development environment",
        "Database design principles and best practices",
        "Introduction to SQL syntax and basic commands",
        "Understanding tables, schemas, and database organization"
      ]
    },
    {
      title: "Data Retrieval: SELECT Statements",
      icon: Table,
      image: "Sql.jpg",
      description: "Master the art of retrieving data from databases using SQL SELECT statements, filtering, and sorting techniques.",
      details: [
        "Writing basic SELECT statements to retrieve data",
        "Using WHERE clause for filtering specific records",
        "Implementing ORDER BY for sorting results",
        "Understanding DISTINCT and TOP/LIMIT clauses",
        "Combining multiple conditions with AND/OR operators"
      ]
    },
    {
      title: "SQL Functions and Aggregation",
      icon: Calculator,
      image: "Sql.jpg",
      description: "Learn to perform calculations and data analysis using SQL's built-in functions and aggregation capabilities.",
      details: [
        "Using aggregate functions (COUNT, SUM, AVG, MIN, MAX)",
        "Implementing string functions for text manipulation",
        "Working with date and time functions",
        "Understanding numeric functions and calculations",
        "Using CASE statements for conditional logic"
      ]
    },
    {
      title: "Grouping and Advanced Aggregation",
      icon: TableProperties,
      image: "Sql.jpg",
      description: "Master advanced data analysis techniques using GROUP BY operations and complex aggregations.",
      details: [
        "Implementing GROUP BY for data summarization",
        "Using HAVING clause to filter grouped results",
        "Creating complex aggregations with multiple functions",
        "Understanding window functions for advanced analysis",
        "Practical examples of business reporting scenarios"
      ]
    },
    {
      title: "Joins and Relationships",
      icon: GitBranch,
      image: "Sql.jpg",
      description: "Learn to combine data from multiple tables using various types of JOIN operations.",
      details: [
        "Understanding table relationships and key concepts",
        "Implementing INNER JOIN for matching records",
        "Using LEFT/RIGHT JOIN for including all records",
        "Working with FULL JOIN for complete datasets",
        "Handling complex joins with multiple tables"
      ]
    },
    {
      title: "Advanced Queries and Data Manipulation",
      icon: Code,
      image: "Sql.jpg",
      description: "Master complex query writing and data modification techniques using subqueries and DML operations.",
      details: [
        "Writing and optimizing subqueries",
        "Implementing nested queries for complex operations",
        "Using INSERT statements for data addition",
        "Performing UPDATE operations safely",
        "Managing data deletion with DELETE statements"
      ]
    }
  ];

  return (
    <><NavBar />
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <header className="text-center mb-20">
          <h1 className="text-5xl font-bold text-gray-900 mb-6">
            SQL for Data Analytics
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto leading-relaxed">
            Master database management and data analysis with SQL through hands-on exercises and real-world projects. 
            This comprehensive course covers everything from basics to advanced concepts across 
            14 interactive sessions designed for practical learning.
          </p>
        </header>
        
        <main className="space-y-12">
          {sections.map((section, index) => (
            <CourseSection key={index} {...section} />
          ))}
        </main>
      </div>
    </div>
    <Footer />
    </>
  );
};

export default SQLCourse;