import React from "react";
import { NavLink } from "react-router-dom";
import { HomeIcon } from "lucide-react";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";

const NotFound = () => {
  return (
    <>
    <NavBar/>
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="text-center space-y-6 p-8">
        <div className="space-y-2">
          <h1 className="text-6xl font-bold text-gray-900">404</h1>
          <h2 className="text-3xl font-semibold text-gray-800">Page Not Found</h2>
        </div>
        
        <div className="max-w-md mx-auto">
          <p className="text-gray-600 text-lg">
            Oops! The page you're looking for seems to have gone missing. 
            Let's get you back on track.
          </p>
        </div>

        <NavLink 
          to="/"
          className="inline-flex items-center px-6 py-3 text-base font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors duration-200 gap-2"
        >
          <HomeIcon className="w-5 h-5" />
          Back to Home
        </NavLink>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default NotFound;
