import React, { useState, useRef } from "react";
import {
  Truck,
  Clock,
  CheckCircle2,
  Shield,
  Calendar,
  Users,
  FileText,
  Globe,
  ChevronRight,
  Mail,
  AlertCircle,
  Timer,
  Settings,
  ClipboardList
} from "lucide-react";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";

const DeliveryPolicy = () => {
  const [activeSection, setActiveSection] = useState("process");
  
  // Create refs for each section
  const sectionRefs = {
    process: useRef(null),
    timeframes: useRef(null),
    quality: useRef(null),
    support: useRef(null),
    contact: useRef(null),
  };

  const sections = [
    { id: "process", title: "Delivery Process", icon: Truck },
    { id: "timeframes", title: "Delivery Timeframes", icon: Clock },
    { id: "quality", title: "Quality Assurance", icon: CheckCircle2 },
    { id: "support", title: "Support & SLAs", icon: Users },
    { id: "contact", title: "Contact Us", icon: Mail },
  ];

  // Handle click on navigation items
  const handleSectionClick = (sectionId) => {
    setActiveSection(sectionId);
    sectionRefs[sectionId].current?.scrollIntoView({ 
      behavior: "smooth",
      block: "start"
    });
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <NavBar />

      {/* Hero Section */}
      <div className="relative overflow-hidden bg-gradient-to-r from-blue-700 to-blue-900 py-24">
        <div className="absolute inset-0 bg-blue-800 opacity-20"></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="text-center">
            <div className="mb-8 inline-flex items-center justify-center w-20 h-20 rounded-full bg-white/10 backdrop-blur-sm">
              <Truck className="w-10 h-10 text-white" />
            </div>
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Service Delivery Policy
            </h1>
            <p className="text-xl text-blue-100 max-w-2xl mx-auto">
              Our commitment to excellence in service delivery and timely execution
            </p>
          </div>
        </div>
      </div>

      {/* Feature Cards */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 -mt-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          {[
            {
              icon: Clock,
              title: "Timely Delivery",
              desc: "Guaranteed timelines",
            },
            { 
              icon: CheckCircle2, 
              title: "Quality Assured", 
              desc: "Rigorous standards" 
            },
            {
              icon: Users,
              title: "Client Support",
              desc: "24/7 assistance",
            },
            {
              icon: Shield,
              title: "Service Guarantee",
              desc: "Satisfaction assured",
            },
          ].map(({ icon: Icon, title, desc }) => (
            <div
              key={title}
              className="bg-white rounded-xl shadow-lg p-6 transform hover:scale-105 transition-all duration-300"
            >
              <div className="flex items-center space-x-4">
                <div className="p-3 bg-blue-100 rounded-lg">
                  <Icon className="w-6 h-6 text-blue-600" />
                </div>
                <div>
                  <h3 className="font-medium text-gray-900">{title}</h3>
                  <p className="text-sm text-gray-500">{desc}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          {/* Sidebar Navigation */}
          <div className="hidden lg:block lg:col-span-3">
            <nav className="sticky top-6">
              <div className="bg-white rounded-xl shadow-sm p-4 mb-6">
                <div className="flex items-center space-x-2 mb-4 text-blue-600">
                  <Globe className="w-5 h-5" />
                  <span className="font-medium">Last Updated:</span>
                </div>
                <p className="text-gray-600">December 24, 2024</p>
              </div>
              <ul className="space-y-2">
                {sections.map((section) => {
                  const IconComponent = section.icon;
                  return (
                    <li key={section.id}>
                      <button
                        onClick={() => handleSectionClick(section.id)}
                        className={`w-full flex items-center space-x-3 px-4 py-3 text-left rounded-lg transition-all duration-200 ${
                          activeSection === section.id
                            ? "bg-blue-50 text-blue-600 shadow-sm"
                            : "hover:bg-gray-50 text-gray-600"
                        }`}
                      >
                        <IconComponent className="w-5 h-5" />
                        <span className="text-sm font-medium">
                          {section.title}
                        </span>
                        <ChevronRight
                          className={`w-4 h-4 ml-auto transition-transform duration-200 ${
                            activeSection === section.id ? "transform rotate-90" : ""
                          }`}
                        />
                      </button>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>

          {/* Content Area */}
          <div className="lg:col-span-9">
            <div className="bg-white rounded-xl shadow-sm p-8">
              {/* Delivery Process Section */}
              <section ref={sectionRefs.process} id="process" className="mb-12">
                <div className="flex items-center space-x-3 mb-6">
                  <Truck className="w-6 h-6 text-blue-600" />
                  <h2 className="text-2xl font-bold text-gray-900">
                    Service Delivery Process
                  </h2>
                </div>
                <div className="space-y-6">
                  <p className="text-gray-600 leading-relaxed">
                    Our structured delivery process ensures consistent quality and timely execution:
                  </p>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {[
                      "Initial consultation and requirements gathering",
                      "Project scope documentation",
                      "Timeline establishment",
                      "Regular progress updates",
                      "Quality assurance testing",
                      "Final delivery and support",
                    ].map((item) => (
                      <div
                        key={item}
                        className="flex items-center space-x-3 p-4 bg-gray-50 rounded-lg hover:bg-blue-50 transition-colors duration-200"
                      >
                        <CheckCircle2 className="w-5 h-5 text-blue-600" />
                        <span className="text-gray-700">{item}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </section>

              {/* Delivery Timeframes Section */}
              <section ref={sectionRefs.timeframes} id="timeframes" className="mb-12">
                <div className="flex items-center space-x-3 mb-6">
                  <Clock className="w-6 h-6 text-blue-600" />
                  <h2 className="text-2xl font-bold text-gray-900">
                    Delivery Timeframes
                  </h2>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {[
                    {
                      title: "Standard Consultations",
                      content: "24-48 hours response time",
                      icon: Calendar,
                    },
                    {
                      title: "Project Proposals",
                      content: "3-5 business days turnaround",
                      icon: FileText,
                    },
                    {
                      title: "Custom Solutions",
                      content: "Timeline based on project scope",
                      icon: Settings,
                    },
                    {
                      title: "Training Sessions",
                      content: "Scheduled per client convenience",
                      icon: Users,
                    },
                  ].map((item) => (
                    <div
                      key={item.title}
                      className="bg-gray-50 p-6 rounded-lg hover:bg-blue-50 transition-colors duration-200"
                    >
                      <h3 className="text-lg font-medium text-gray-900 mb-3">
                        {item.title}
                      </h3>
                      <p className="text-gray-600">{item.content}</p>
                    </div>
                  ))}
                </div>
              </section>

              {/* Quality Assurance Section */}
              <section ref={sectionRefs.quality} id="quality" className="mb-12">
                <div className="flex items-center space-x-3 mb-6">
                  <CheckCircle2 className="w-6 h-6 text-blue-600" />
                  <h2 className="text-2xl font-bold text-gray-900">
                    Quality Assurance
                  </h2>
                </div>
                <div className="space-y-6">
                  <p className="text-gray-600 leading-relaxed">
                    Our comprehensive quality assurance process includes:
                  </p>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {[
                      "Multiple rounds of testing",
                      "Industry standards compliance",
                      "Performance optimization",
                      "Security verification",
                      "Client feedback integration",
                      "Continuous improvement",
                    ].map((item) => (
                      <div
                        key={item}
                        className="flex items-center space-x-3 p-4 bg-gray-50 rounded-lg"
                      >
                        <CheckCircle2 className="w-5 h-5 text-blue-600" />
                        <span className="text-gray-700">{item}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </section>

              {/* Support & SLAs Section */}
              <section ref={sectionRefs.support} id="support" className="mb-12">
                <div className="flex items-center space-x-3 mb-6">
                  <Users className="w-6 h-6 text-blue-600" />
                  <h2 className="text-2xl font-bold text-gray-900">
                    Support & SLAs
                  </h2>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {[
                    {
                      title: "Response Time",
                      content: "Within 4 business hours",
                      icon: Timer,
                    },
                    {
                      title: "Issue Resolution",
                      content: "24-48 hours guaranteed",
                      icon: AlertCircle,
                    },
                    {
                      title: "Progress Updates",
                      content: "Regular status reports",
                      icon: ClipboardList,
                    },
                    {
                      title: "Emergency Support",
                      content: "24/7 critical issue assistance",
                      icon: Shield,
                    },
                  ].map((item) => (
                    <div
                      key={item.title}
                      className="bg-gray-50 p-6 rounded-lg"
                    >
                      <h3 className="text-lg font-medium text-gray-900 mb-3">
                        {item.title}
                      </h3>
                      <p className="text-gray-600">{item.content}</p>
                    </div>
                  ))}
                </div>
              </section>

              {/* Contact Section */}
              <section ref={sectionRefs.contact} id="contact">
                <div className="bg-gradient-to-r from-blue-50 to-blue-100 rounded-xl p-8">
                  <div className="flex items-center space-x-3 mb-6">
                    <Mail className="w-6 h-6 text-blue-600" />
                    <h2 className="text-2xl font-bold text-gray-900">
                      Contact Us
                    </h2>
                  </div>
                  <p className="text-gray-600 mb-6">
                    For any questions about our delivery process or to discuss your specific needs:
                  </p>
                  <div className="inline-flex items-center space-x-2 bg-white px-4 py-3 rounded-lg shadow-sm">
                    <Mail className="w-5 h-5 text-blue-600" />
                    <a
                      href="mailto:info@genvorix.com"
                      className="text-blue-600 hover:text-blue-700 font-medium"
                    >
                      info@genvorix.com
                    </a>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DeliveryPolicy;