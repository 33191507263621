import React, { useState, useRef } from 'react';
import { ScrollText, Shield, Book, FileCheck, Phone, Mail, Globe, ChevronRight, AlertCircle, Settings, Users, Lock } from 'lucide-react';
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";

const TermsAndConditions = () => {
  const [activeSection, setActiveSection] = useState('1');
  
  // Create refs for each section
  const sectionRefs = {
    '1': useRef(null),
    '2': useRef(null),
    '3': useRef(null),
    '4': useRef(null),
    '5': useRef(null),
    '6': useRef(null),
    '7': useRef(null),
    '8': useRef(null),
    '9': useRef(null),
  };

  const sections = [
    { id: '1', title: 'Introduction', icon: Book },
    { id: '2', title: 'Services', icon: Settings },
    { id: '3', title: 'Course Enrollment', icon: Users },
    { id: '4', title: 'Intellectual Property', icon: Lock },
    { id: '5', title: 'Code of Conduct', icon: AlertCircle },
    { id: '6', title: 'Liability', icon: Shield },
    { id: '7', title: 'Privacy', icon: Lock },
    { id: '8', title: 'Updates', icon: Settings },
    { id: '9', title: 'Contact', icon: Phone }
  ];

  const handleSectionClick = (sectionId) => {
    setActiveSection(sectionId);
    sectionRefs[sectionId].current?.scrollIntoView({ 
      behavior: "smooth",
      block: "start" 
    });
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <NavBar />
      
      {/* Hero Section */}
      <div className="relative overflow-hidden bg-blue-600 py-24">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-blue-800 opacity-90"></div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <div className="mb-6 inline-flex items-center justify-center w-16 h-16 rounded-full bg-white/10">
              <FileCheck className="w-8 h-8 text-white" />
            </div>
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Terms and Conditions
            </h1>
            <p className="text-xl text-blue-100 max-w-2xl mx-auto">
              Please read these terms carefully before using our services
            </p>
          </div>
        </div>
      </div>

      {/* Info Cards */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 -mt-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white rounded-xl shadow-lg p-6 transform hover:scale-105 transition-transform duration-300">
            <div className="flex items-center space-x-4">
              <div className="p-3 bg-blue-100 rounded-lg">
                <ScrollText className="w-6 h-6 text-blue-600" />
              </div>
              <div>
                <p className="text-sm font-medium text-gray-900">Last Updated</p>
                <p className="text-sm text-gray-500">Dec 20, 2024</p>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-xl shadow-lg p-6 transform hover:scale-105 transition-transform duration-300">
            <div className="flex items-center space-x-4">
              <div className="p-3 bg-blue-100 rounded-lg">
                <Shield className="w-6 h-6 text-blue-600" />
              </div>
              <div>
                <p className="text-sm font-medium text-gray-900">Protected by</p>
                <p className="text-sm text-gray-500">Data Protection Laws</p>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-xl shadow-lg p-6 transform hover:scale-105 transition-transform duration-300">
            <div className="flex items-center space-x-4">
              <div className="p-3 bg-blue-100 rounded-lg">
                <Globe className="w-6 h-6 text-blue-600" />
              </div>
              <div>
                <p className="text-sm font-medium text-gray-900">Guidelines</p>
                <p className="text-sm text-gray-500">Professional Standards</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          {/* Navigation Sidebar */}
          <div className="hidden lg:block lg:col-span-3">
            <nav className="sticky top-6">
              <ul className="space-y-2">
                {sections.map((section) => {
                  const IconComponent = section.icon;
                  return (
                    <li key={section.id}>
                      <button
                        onClick={() => handleSectionClick(section.id)}
                        className={`w-full flex items-center space-x-3 px-4 py-3 text-left rounded-lg transition-colors duration-200 ${
                          activeSection === section.id
                            ? 'bg-blue-50 text-blue-600'
                            : 'hover:bg-gray-50 text-gray-600'
                        }`}
                      >
                        <IconComponent className="w-5 h-5" />
                        <span className="text-sm font-medium">{section.title}</span>
                        <ChevronRight className={`w-4 h-4 ml-auto transition-transform duration-200 ${
                          activeSection === section.id ? 'transform rotate-90' : ''
                        }`} />
                      </button>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>

          {/* Content Area */}
          <div className="lg:col-span-9">
            <div className="prose max-w-none">
              <div className="bg-white rounded-xl shadow-sm p-8">
                {/* Introduction Section */}
                <section ref={sectionRefs['1']} id="introduction" className="mb-12">
                  <h2 className="text-2xl font-bold text-gray-900 mb-6 flex items-center">
                    <Book className="w-6 h-6 mr-3 text-blue-600" />
                    Introduction
                  </h2>
                  <p className="text-gray-600 leading-relaxed mb-4">
                    Welcome to our Training and Consultancy Services. By accessing and using our services, 
                    including but not limited to our training programs in Python, SQL, and Power BI, you 
                    agree to be bound by these Terms and Conditions.
                  </p>
                  <p className="text-gray-600 leading-relaxed">
                    These terms govern your use of our platform, services, and training materials. Please read them carefully before proceeding with any course enrollment or service engagement.
                  </p>
                </section>

                {/* Services Section */}
                <section ref={sectionRefs['2']} id="services" className="mb-12">
                  <h2 className="text-2xl font-bold text-gray-900 mb-6 flex items-center">
                    <Settings className="w-6 h-6 mr-3 text-blue-600" />
                    Our Services
                  </h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                    {[
                      "Professional training programs",
                      "Business consultancy services",
                      "Course materials and resources",
                      "Online training sessions",
                      "Certification programs",
                      "Personalized mentoring"
                    ].map((service) => (
                      <div key={service} className="flex items-center space-x-3 p-4 bg-gray-50 rounded-lg hover:bg-blue-50 transition-colors duration-200">
                        <div className="w-2 h-2 rounded-full bg-blue-600"></div>
                        <span className="text-gray-700">{service}</span>
                      </div>
                    ))}
                  </div>
                </section>

                {/* Course Enrollment Section */}
                <section ref={sectionRefs['3']} id="enrollment" className="mb-12">
                  <h2 className="text-2xl font-bold text-gray-900 mb-6 flex items-center">
                    <Users className="w-6 h-6 mr-3 text-blue-600" />
                    Course Enrollment
                  </h2>
                  <div className="space-y-4">
                    <p className="text-gray-600">To enroll in our courses, you must:</p>
                    <ul className="list-disc pl-6 space-y-2 text-gray-600">
                      <li>Be at least 18 years of age</li>
                      <li>Provide accurate registration information</li>
                      <li>Meet any specified prerequisites for the course</li>
                      <li>Complete the payment process</li>
                      <li>Agree to our attendance and participation policies</li>
                    </ul>
                  </div>
                </section>

                {/* Intellectual Property Section */}
                <section ref={sectionRefs['4']} id="intellectual-property" className="mb-12">
                  <h2 className="text-2xl font-bold text-gray-900 mb-6 flex items-center">
                    <Lock className="w-6 h-6 mr-3 text-blue-600" />
                    Intellectual Property
                  </h2>
                  <p className="text-gray-600 mb-4">
                    All training materials, including but not limited to presentations, code examples, 
                    documentation, and exercises, are protected by intellectual property rights.
                  </p>
                  <div className="bg-blue-50 p-4 rounded-lg">
                    <p className="text-gray-700">You may not:</p>
                    <ul className="list-disc pl-6 mt-2 space-y-2 text-gray-600">
                      <li>Share or distribute course materials</li>
                      <li>Record training sessions without permission</li>
                      <li>Use materials for commercial purposes</li>
                      <li>Modify or create derivative works</li>
                    </ul>
                  </div>
                </section>

                {/* Code of Conduct Section */}
                <section ref={sectionRefs['5']} id="code-of-conduct" className="mb-12">
                  <h2 className="text-2xl font-bold text-gray-900 mb-6 flex items-center">
                    <AlertCircle className="w-6 h-6 mr-3 text-blue-600" />
                    Code of Conduct
                  </h2>
                  <p className="text-gray-600 mb-4">
                    We maintain a professional and respectful learning environment. All participants must:
                  </p>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {[
                      "Respect fellow learners and instructors",
                      "Maintain professional communication",
                      "Participate actively in sessions",
                      "Follow course guidelines",
                      "Protect confidential information",
                      "Report any concerns promptly"
                    ].map((item) => (
                      <div key={item} className="flex items-center space-x-3 p-4 bg-gray-50 rounded-lg">
                        <div className="w-2 h-2 rounded-full bg-blue-600"></div>
                        <span className="text-gray-700">{item}</span>
                      </div>
                    ))}
                  </div>
                </section>

                {/* Liability Section */}
                <section ref={sectionRefs['6']} id="liability" className="mb-12">
                  <h2 className="text-2xl font-bold text-gray-900 mb-6 flex items-center">
                    <Shield className="w-6 h-6 mr-3 text-blue-600" />
                    Liability
                  </h2>
                  <div className="bg-gray-50 p-6 rounded-lg">
                    <p className="text-gray-600 mb-4">
                      While we strive to provide accurate and up-to-date information, we:
                    </p>
                    <ul className="space-y-3 text-gray-600">
                      <li className="flex items-start space-x-2">
                        <div className="w-2 h-2 mt-2 rounded-full bg-blue-600"></div>
                        <span>Are not liable for any indirect or consequential losses</span>
                      </li>
                      <li className="flex items-start space-x-2">
                        <div className="w-2 h-2 mt-2 rounded-full bg-blue-600"></div>
                        <span>Do not guarantee specific outcomes or results</span>
                      </li>
                      <li className="flex items-start space-x-2">
                        <div className="w-2 h-2 mt-2 rounded-full bg-blue-600"></div>
                        <span>Reserve the right to modify course content</span>
                      </li>
                    </ul>
                  </div>
                </section>

                {/* Privacy Section */}
                <section ref={sectionRefs['7']} id="privacy" className="mb-12">
                  <h2 className="text-2xl font-bold text-gray-900 mb-6 flex items-center">
                    <Lock className="w-6 h-6 mr-3 text-blue-600" />
                    Privacy
                  </h2>
                  <p className="text-gray-600 mb-4">
                    Your privacy is important to us. We handle your data in accordance with our Privacy Policy, which includes:
                  </p>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {[
                      "Data collection practices",
                      "Information security measures",
                      "User rights and access",
                      "Data retention policies"
                    ].map((item) => (
                      <div key={item} className="flex items-center space-x-3 p-4 bg-gray-50 rounded-lg">
                        <div className="w-2 h-2 rounded-full bg-blue-600"></div>
                        <span className="text-gray-700">{item}</span>
                      </div>
                    ))}
                  </div>
                </section>

                {/* Updates Section */}
                <section ref={sectionRefs['8']} id="updates" className="mb-12">
                  <h2 className="text-2xl font-bold text-gray-900 mb-6 flex items-center">
                    <Settings className="w-6 h-6 mr-3 text-blue-600" />
                    Updates to Terms
                  </h2>
                  <p className="text-gray-600 mb-4">
                    We may update these terms from time to time. Changes will be effective upon posting to this page.
                    Major changes will be communicated directly to users.
                  </p>
                </section>

                {/* Contact Section */}
                <section ref={sectionRefs['9']} id="contact" className="mt-12 bg-gradient-to-r from-blue-50 to-blue-100 rounded-xl p-8">
                  <h2 className="text-2xl font-bold text-gray-900 mb-6 flex items-center">
                    <Phone className="w-6 h-6 mr-3 text-blue-600" />
                    Contact Us
                  </h2>
                  <div className="flex flex-col space-y-4">
                    <p className="text-gray-600 mb-4">
                      If you have any questions about these Terms and Conditions, please contact us:
                    </p>
                    <div className="flex items-center space-x-3">
                      <Mail className="w-5 h-5 text-blue-600" />
                      <a href="mailto:info@genvorix.com" className="text-blue-600 hover:text-blue-700">
                        info@genvorix.com
                      </a>
                    </div>
                  </div>
                </section>

                {/* Last Updated Footer */}
                <div className="mt-12 pt-6 border-t border-gray-200">
                  <p className="text-sm text-gray-500">
                    Last Updated: December 20, 2024
                  </p>
                </div>
              </div>
            </div>
          </div>
      </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;