import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// New imports matching the file-based structure
import Home from "./app/page";
import AboutUs from "./app/about/page";
import ContactUs from "./app/contact/page";
import PricingAndRefunds from "./app/refund/page";
import NotFound from "./app/not-found/page";
import Pricing from "./app/pricing/main/page";
import Python from "./app/pricing/python/page";
import Sqlpackage from "./app/pricing/sqlpackage/page";
import Aipackage from "./app/pricing/aipackage/page";
import Powerbi from "./app/pricing/powerbi/page";
import TermsAndConditions from "./app/termsandconditions/page";
import PrivacyPolicy from "./app/privacypolicy/page";
import DeliveryPolicy from "./app/deliverypolicy/page";
import CancellationPolicy from "./app/cancellationpolicy/page";
import FAQsPage from "./app/faq/page";

import "./App.css";

// Rest of your App component remains the same
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/refund" element={<PricingAndRefunds />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/pricing/python" element={<Python />} />
        <Route path="/pricing/sqlpackage" element={<Sqlpackage />} />
        <Route path="/pricing/aipackage" element={<Aipackage />} />
        <Route path="/pricing/powerbi" element={<Powerbi />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/deliverypolicy" element={<DeliveryPolicy />} />
        <Route path="/cancellationpolicy" element={<CancellationPolicy />} />
        <Route path="/faq" element={<FAQsPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;