import React, { useState, useRef } from "react";
import {
  Shield,
  Lock,
  Eye,
  Database,
  Bell,
  Users,
  FileKey,
  Globe,
  ChevronRight,
  Mail,
  AlertCircle,
  CheckCircle2,
  Book,
  CircleSlash,
  Settings,
  FileText,
} from "lucide-react";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";

const PrivacyPolicy = () => {
  const [activeSection, setActiveSection] = useState("information");
  
  // Create refs for each section
  const sectionRefs = {
    information: useRef(null),
    usage: useRef(null),
    protection: useRef(null),
    rights: useRef(null),
    contact: useRef(null),
  };

  const sections = [
    { id: "information", title: "Information We Collect", icon: Database },
    { id: "usage", title: "How We Use Information", icon: FileKey },
    { id: "protection", title: "Data Protection", icon: Lock },
    { id: "rights", title: "Your Rights", icon: Users },
    { id: "contact", title: "Contact Us", icon: Mail },
  ];

  // Handle click on navigation items
  const handleSectionClick = (sectionId) => {
    setActiveSection(sectionId);
    // Scroll to the section smoothly
    sectionRefs[sectionId].current?.scrollIntoView({ 
      behavior: "smooth",
      block: "start"
    });
  };
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <NavBar />

      {/* Hero Section with Enhanced Design */}
      <div className="relative overflow-hidden bg-gradient-to-r from-blue-700 to-blue-900 py-24">
        <div className="absolute inset-0 bg-blue-800 opacity-20"></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="text-center">
            <div className="mb-8 inline-flex items-center justify-center w-20 h-20 rounded-full bg-white/10 backdrop-blur-sm">
              <Shield className="w-10 h-10 text-white" />
            </div>
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Privacy Policy
            </h1>
            <p className="text-xl text-blue-100 max-w-2xl mx-auto">
              We value your privacy and are committed to protecting your
              personal data
            </p>
          </div>
        </div>
      </div>

      {/* Feature Cards */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 -mt-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          {[
            {
              icon: Lock,
              title: "Data Security",
              desc: "Enterprise-grade protection",
            },
            { icon: Eye, title: "Transparency", desc: "Clear data practices" },
            {
              icon: Database,
              title: "Data Protection",
              desc: "GDPR Compliant",
            },
            {
              icon: Bell,
              title: "Regular Updates",
              desc: "Policy notifications",
            },
          ].map(({ icon: Icon, title, desc }) => (
            <div
              key={title}
              className="bg-white rounded-xl shadow-lg p-6 transform hover:scale-105 transition-all duration-300"
            >
              <div className="flex items-center space-x-4">
                <div className="p-3 bg-blue-100 rounded-lg">
                  <Icon className="w-6 h-6 text-blue-600" />
                </div>
                <div>
                  <h3 className="font-medium text-gray-900">{title}</h3>
                  <p className="text-sm text-gray-500">{desc}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          {/* Sidebar Navigation */}
          <div className="hidden lg:block lg:col-span-3">
            <nav className="sticky top-6">
              <div className="bg-white rounded-xl shadow-sm p-4 mb-6">
                <div className="flex items-center space-x-2 mb-4 text-blue-600">
                  <Globe className="w-5 h-5" />
                  <span className="font-medium">Last Updated:</span>
                </div>
                <p className="text-gray-600">December 20, 2024</p>
              </div>
              <ul className="space-y-2">
                {sections.map((section) => {
                  const IconComponent = section.icon;
                  return (
                    <li key={section.id}>
                      <button
                        onClick={() => handleSectionClick(section.id)}
                        className={`w-full flex items-center space-x-3 px-4 py-3 text-left rounded-lg transition-all duration-200 ${
                          activeSection === section.id
                            ? "bg-blue-50 text-blue-600 shadow-sm"
                            : "hover:bg-gray-50 text-gray-600"
                        }`}
                      >
                        <IconComponent className="w-5 h-5" />
                        <span className="text-sm font-medium">
                          {section.title}
                        </span>
                        <ChevronRight
                          className={`w-4 h-4 ml-auto transition-transform duration-200 ${
                            activeSection === section.id
                              ? "transform rotate-90"
                              : ""
                          }`}
                        />
                      </button>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>

          {/* Content Area */}
          <div className="lg:col-span-9">
            <div className="bg-white rounded-xl shadow-sm p-8">
              {/* Information We Collect */}
              <section ref={sectionRefs.information} id="information" className="mb-12">
                <div className="flex items-center space-x-3 mb-6">
                  <Database className="w-6 h-6 text-blue-600" />
                  <h2 className="text-2xl font-bold text-gray-900">
                    Information We Collect
                  </h2>
                </div>
                <div className="space-y-6">
                  <p className="text-gray-600 leading-relaxed">
                    We collect various types of information to provide and
                    improve our services:
                  </p>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {[
                      "Name and contact details",
                      "Course enrollment information",
                      "Payment information",
                      "Training progress and performance",
                      "Technical skills and experience",
                      "Feedback and survey responses",
                    ].map((item) => (
                      <div
                        key={item}
                        className="flex items-center space-x-3 p-4 bg-gray-50 rounded-lg hover:bg-blue-50 transition-colors duration-200"
                      >
                        <CheckCircle2 className="w-5 h-5 text-blue-600" />
                        <span className="text-gray-700">{item}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </section>

              {/* How We Use Information */}
              <section ref={sectionRefs.usage} id="usage" className="mb-12">
                <div className="flex items-center space-x-3 mb-6">
                  <FileKey className="w-6 h-6 text-blue-600" />
                  <h2 className="text-2xl font-bold text-gray-900">
                    How We Use Your Information
                  </h2>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {[
                    {
                      title: "Course Delivery",
                      content:
                        "To provide you with access to our training programs and materials",
                      icon: Book,
                    },
                    {
                      title: "Communication",
                      content:
                        "To send important updates about your courses and our services",
                      icon: Mail,
                    },
                    {
                      title: "Improvement",
                      content:
                        "To enhance our training programs and consultancy services",
                      icon: AlertCircle,
                    },
                    {
                      title: "Support",
                      content:
                        "To provide technical and learning support during your training",
                      icon: Users,
                    },
                  ].map((item) => (
                    <div
                      key={item.title}
                      className="bg-gray-50 p-6 rounded-lg hover:bg-blue-50 transition-colors duration-200"
                    >
                      <h3 className="text-lg font-medium text-gray-900 mb-3">
                        {item.title}
                      </h3>
                      <p className="text-gray-600">{item.content}</p>
                    </div>
                  ))}
                </div>
              </section>
              <div className="lg:col-span-9">
                <div className="bg-white rounded-xl shadow-sm p-8">
                  {/* Your Rights */}
                  <section ref={sectionRefs.rights} id="rights" className="mb-12">
                <div className="flex items-center space-x-3 mb-6">
                  <Users className="w-6 h-6 text-blue-600" />
                  <h2 className="text-2xl font-bold text-gray-900">
                    Your Rights
                  </h2>
                </div>
                    <div className="space-y-6">
                      <p className="text-gray-600 leading-relaxed">
                        To provide our services effectively, we collect and
                        process the following types of information:
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {[
                          "Personal identification information (Name, email, phone)",
                          "Professional background and experience",
                          "Device and browser information",
                          "Training preferences and history",
                          "Assessment results and progress data",
                          "Communication preferences",
                        ].map((item) => (
                          <div
                            key={item}
                            className="flex items-center space-x-3 p-4 bg-gray-50 rounded-lg"
                          >
                            <CheckCircle2 className="w-5 h-5 text-blue-600" />
                            <span className="text-gray-700">{item}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </section>

                  {/* Data Protection */}
                  <section ref={sectionRefs.protection} id="protection" className="mb-12">
                <div className="flex items-center space-x-3 mb-6">
                  <Lock className="w-6 h-6 text-blue-600" />
                  <h2 className="text-2xl font-bold text-gray-900">
                    Data Protection
                  </h2>
                </div>
                    <div className="space-y-6">
                      <p className="text-gray-600 leading-relaxed">
                        We implement robust security measures to protect your
                        personal information:
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {[
                          {
                            title: "Encryption",
                            content:
                              "All data is encrypted during transmission and storage",
                            icon: Lock,
                          },
                          {
                            title: "Access Controls",
                            content:
                              "Strict access controls and authentication measures",
                            icon: CircleSlash,
                          },
                          {
                            title: "Regular Audits",
                            content:
                              "Continuous monitoring and security assessments",
                            icon: Settings,
                          },
                          {
                            title: "Compliance",
                            content:
                              "Adherence to international data protection standards",
                            icon: FileText,
                          },
                        ].map((item) => (
                          <div
                            key={item.title}
                            className="bg-gray-50 p-6 rounded-lg"
                          >
                            <h3 className="text-lg font-medium text-gray-900 mb-3">
                              {item.title}
                            </h3>
                            <p className="text-gray-600">{item.content}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </section>
                </div>
              </div>

              {/* Contact Section */}
              <section ref={sectionRefs.contact} id="contact" className="mt-12">
                <div className="bg-gradient-to-r from-blue-50 to-blue-100 rounded-xl p-8">
                  <div className="flex items-center space-x-3 mb-6">
                    <Mail className="w-6 h-6 text-blue-600" />
                    <h2 className="text-2xl font-bold text-gray-900">
                      Contact Us
                    </h2>
                  </div>
                  <p className="text-gray-600 mb-6">
                    If you have any questions about this Privacy Policy, please
                    contact our Data Protection Officer at:
                  </p>
                  <div className="inline-flex items-center space-x-2 bg-white px-4 py-3 rounded-lg shadow-sm">
                    <Mail className="w-5 h-5 text-blue-600" />
                    <a
                      href="mailto:info@genvorix.com"
                      className="text-blue-600 hover:text-blue-700 font-medium"
                    >
                      info@genvorix.com
                    </a>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
